export const SET_IS_LOGIN = "extIsLogin";
export const SET_TOKEN = "extToken";
export const SET_USERNAME = "extUserName";
export const SET_ACCOUNTNAME = "extAccountName";
export const SET_USERID = "extUserId";
export const SET_OPENID = "extOpenId";
export const SET_ACCOUNT = "extAccount";
export const SET_PHONE = "extPhone";
export const SET_PLATFORM = "extPlatform";
export const SET_IS_MANAGE = "extIsManage";
export const SET_MANAGEID = "extManageId";
export const SET_MANAGENAME = "extManageName";
export const SET_ROLES = "extRoles";
export const SET_ALL_DATA = "extUser";
export const SET_DICT_LIST = "extDictList";
export const SET_DICT_LEVE_LIST = "extDictLeveList";
export const SET_LOOP_CONFIG = "extLoopInstanceConfig";
export const SET_ORDER_LOOP = "extOrderLoopInstance";
export const SET_BROWSER_TYPE = "extBrowserType";
export const SET_WORK_DATA = "extWorkData";
export const SET_HOLIDAY_DATA = "extHolidayData";
export const SET_ORG_CODE = "extOrgCode";