import Cookie from 'js-cookie'
var storage = window.localStorage
export function getKey(key) {
  return storage.getItem(key)
}
export function setKey(key, val) {
  return storage.setItem(key, val)
}
export function removeKey(key) {
  return storage.removeItem(key)
}
export function getCookieKey(key) {
  return Cookie.get(key)
}
export function setCookieKey(key, val) {
  return Cookie.set(key, val)
}
export function removeCookieKey(key) {
  return Cookie.remove(key)
}