export default {
    isLogin: (state) => state.app.isLogin,
    userId: (state) => state.app.userId,
    token: (state) => state.app.token,
    userName: (state) => state.app.userName,
    openId: (state) => state.app.openId,
    accountName: (state) => state.app.accountName,
    userPhone: (state) => state.app.userPhone,
    account: (state) => state.app.account,
    platform: (state) => state.app.platform,
    isManage: (state) => state.app.isManage,
    manageId: (state) => state.app.manageId,
    manageName: (state) => state.app.manageName,
    roles: (state) => state.app.roles,
    dictList: (state) => state.app.dictList,
    dictLeveList: (state) => state.app.dictLeveList,
    loopInstanceConfig: (state) => state.app.loopInstanceConfig,
    orderLoopInstance: (state) => state.app.orderLoopInstance,
    tagsView: (state) => state.app.tagsView,
    extBrowserType: (state) => state.app.extBrowserType,
    workData: (state) => state.app.workData,
    holidayData: (state) => state.app.holidayData,
    orgCode: (state) => state.app.orgCode,
};