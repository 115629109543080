import {
    SET_TOKEN,
    SET_USERNAME,
    SET_ACCOUNTNAME,
    SET_USERID,
    SET_OPENID,
    SET_ACCOUNT,
    SET_PHONE,
    SET_PLATFORM,
    SET_IS_MANAGE,
    SET_MANAGEID,
    SET_MANAGENAME,
    SET_ROLES,
    SET_ALL_DATA,
    SET_DICT_LIST,
    SET_DICT_LEVE_LIST,
    SET_LOOP_CONFIG,
    SET_ORDER_LOOP, SET_IS_LOGIN,
    SET_BROWSER_TYPE,
    SET_WORK_DATA,
    SET_HOLIDAY_DATA,
    SET_ORG_CODE
} from "./type.js";
import tagsView from './tagsView'
import createPersistedState from 'vuex-persistedstate'

export default {
    namespaced: true,
    plugins: [createPersistedState()],
    state: {
        isLogin: sessionStorage.getItem(SET_IS_LOGIN) || false,
        token: sessionStorage.getItem(SET_TOKEN) || "",
        userId: sessionStorage.getItem(SET_USERID) || "",
        openId: sessionStorage.getItem(SET_OPENID) || "",
        accountName: sessionStorage.getItem(SET_ACCOUNTNAME) || "",
        userName: sessionStorage.getItem(SET_USERNAME) || "",
        userPhone: sessionStorage.getItem(SET_PHONE) || "",
        account: sessionStorage.getItem(SET_ACCOUNT) || {},
        platform: sessionStorage.getItem(SET_PLATFORM) || "",
        isManage: sessionStorage.getItem(SET_IS_MANAGE) || "",
        manageId: sessionStorage.getItem(SET_MANAGEID) || "",
        manageName: sessionStorage.getItem(SET_MANAGENAME) || "",
        roles: sessionStorage.getItem(SET_ROLES) || {},
        dictList: sessionStorage.getItem(SET_DICT_LIST) || [],
        dictLeveList: sessionStorage.getItem(SET_DICT_LEVE_LIST) || [],
        loopInstanceConfig: sessionStorage.getItem(SET_LOOP_CONFIG) || [],
        orderLoopInstance: sessionStorage.getItem(SET_ORDER_LOOP) || [],
        extBrowserType: sessionStorage.getItem(SET_BROWSER_TYPE) || 1,
        workData: sessionStorage.getItem(SET_WORK_DATA) || {},
        holidayData: sessionStorage.getItem(SET_HOLIDAY_DATA) || {},
        orgCode: sessionStorage.getItem(SET_ORG_CODE) || '-1',
    },
    mutations: {
        [SET_IS_LOGIN](state, isLogin) {
            state.isLogin = isLogin;
            sessionStorage.setItem(SET_IS_LOGIN, state.isLogin);
        },
        [SET_ORG_CODE](state, orgCode) {
            state.orgCode = orgCode;
            sessionStorage.setItem(SET_ORG_CODE, state.orgCode);
        },
        [SET_TOKEN](state, token) {
            state.token = token;
            sessionStorage.setItem(SET_TOKEN, state.token);
        },
        [SET_USERNAME](state, userName) {
            state.userName = userName;
            sessionStorage.setItem(SET_USERNAME, state.userName);
        },
        [SET_ACCOUNTNAME](state, accountName) {
            state.accountName = accountName;
            sessionStorage.setItem(SET_ACCOUNTNAME, state.accountName);
        },
        [SET_USERID](state, userId) {
            state.userId = userId;
            sessionStorage.setItem(SET_USERID, state.userId);
        },
        [SET_OPENID](state, openId) {
            state.openId = openId;
            sessionStorage.setItem(SET_OPENID, state.openId);
        }
        ,
        [SET_PHONE](state, userPhone) {
            state.userPhone = userPhone;
            sessionStorage.setItem(SET_PHONE, state.userPhone);
        }
        ,
        [SET_ACCOUNT](state, account) {
            state.account = account;
            sessionStorage.setItem(SET_ACCOUNT, state.account);
        }
        ,
        [SET_PLATFORM](state, platform) {
            state.platform = platform;
            sessionStorage.setItem(SET_PLATFORM, state.platform);
        }
        ,
        [SET_IS_MANAGE](state, isManage) {
            state.isManage = isManage;
            sessionStorage.setItem(SET_IS_MANAGE, state.isManage);
        }
        ,
        [SET_MANAGEID](state, manageId) {
            state.manageId = manageId;
            sessionStorage.setItem(SET_MANAGEID, state.manageId);
        }
        ,
        [SET_MANAGENAME](state, manageName) {
            state.manageName = manageName;
            sessionStorage.setItem(SET_MANAGENAME, state.manageName);
        }
        ,
        [SET_ROLES](state, roles) {
            state.roles = roles;
            sessionStorage.setItem(SET_ROLES, state.roles);
        },
        [SET_DICT_LIST](state, dictList) {
            state.dictList = dictList;
            sessionStorage.setItem(SET_DICT_LIST, state.dictList);
        },
        [SET_DICT_LEVE_LIST](state, dictLeveList) {
            state.dictLeveList = dictLeveList;
            sessionStorage.setItem(SET_DICT_LEVE_LIST, state.dictLeveList);
        },
        [SET_LOOP_CONFIG](state, loopInstanceConfig) {
            state.loopInstanceConfig = loopInstanceConfig;
            sessionStorage.setItem(SET_LOOP_CONFIG, state.loopInstanceConfig);
        },
        [SET_ORDER_LOOP](state, orderLoopInstance) {
            state.orderLoopInstance = orderLoopInstance;
            sessionStorage.setItem(SET_ORDER_LOOP, state.orderLoopInstance);
        },
        [SET_BROWSER_TYPE](state, extBrowserType) {
            state.extBrowserType = extBrowserType;
            sessionStorage.setItem(SET_BROWSER_TYPE, state.extBrowserType);
        }, [SET_WORK_DATA](state, workData) {
            state.workData = workData;
            sessionStorage.setItem(SET_WORK_DATA, state.workData);
        },
        [SET_HOLIDAY_DATA](state, holidayData) {
            state.holidayData = holidayData;
            sessionStorage.setItem(SET_HOLIDAY_DATA, state.holidayData);
        },
        [SET_ALL_DATA](state, extUser) {
            state.isLogin = true;
            sessionStorage.setItem(SET_IS_LOGIN, state.isLogin);
            state.orgCode = extUser.orgCode;
            sessionStorage.setItem(SET_ORG_CODE, state.orgCode);
            state.token = extUser.token;
            sessionStorage.setItem(SET_TOKEN, state.token);
            state.userName = extUser.account.name;
            sessionStorage.setItem(SET_USERNAME, state.userName);
            state.accountName = extUser.account.username;
            sessionStorage.setItem(SET_ACCOUNTNAME, state.accountName);
            state.userId = extUser.account.id;
            sessionStorage.setItem(SET_USERID, state.userId);
            state.openId = extUser.account.openid;
            sessionStorage.setItem(SET_OPENID, state.openId);
            state.userPhone = extUser.account.phone;
            sessionStorage.setItem(SET_PHONE, state.userPhone);
            state.account = extUser.account;
            sessionStorage.setItem(SET_ACCOUNT, state.account);
            state.platform = extUser.platform;
            sessionStorage.setItem(SET_PLATFORM, state.platform);
            state.isManage = extUser.isManage;
            sessionStorage.setItem(SET_IS_MANAGE, state.isManage);
            state.manageId = extUser.manageUserId;
            sessionStorage.setItem(SET_MANAGEID, state.manageId);
            state.manageName = extUser.manageUserName;
            sessionStorage.setItem(SET_MANAGENAME, state.manageName);
            state.roles = extUser.extServiceRoles;
            sessionStorage.setItem(SET_ROLES, state.roles);
        }
    },
    actions: {
        [SET_IS_LOGIN]({commit}, isLogin) {
            commit(SET_IS_LOGIN, isLogin);
        },
        [SET_ORG_CODE]({commit}, orgCode) {
            commit(SET_ORG_CODE, orgCode);
        },
        [SET_TOKEN]({commit}, token) {
            commit(SET_TOKEN, token);
        },
        [SET_USERNAME]({commit}, userName) {
            commit(SET_USERNAME, userName);
        },
        [SET_ACCOUNTNAME]({commit}, accountName) {
            commit(SET_ACCOUNTNAME, accountName);
        },
        [SET_USERID]({commit}, userId) {
            commit(SET_USERID, userId);
        },
        [SET_OPENID]({commit}, openId) {
            commit(SET_OPENID, openId);
        },
        [SET_PHONE]({commit}, userPhone) {
            commit(SET_PHONE, userPhone);
        },
        [SET_ACCOUNT]({commit}, account) {
            commit(SET_ACCOUNT, account);
        },
        [SET_PLATFORM]({commit}, platform) {
            commit(SET_PLATFORM, platform);
        },
        [SET_IS_MANAGE]({commit}, isManage) {
            commit(SET_IS_MANAGE, isManage);
        },
        [SET_MANAGEID]({commit}, manageId) {
            commit(SET_MANAGEID, manageId);
        },
        [SET_MANAGENAME]({commit}, manageName) {
            commit(SET_MANAGENAME, manageName);
        },
        [SET_ROLES]({commit}, roles) {
            commit(SET_ROLES, roles);
        },
        [SET_DICT_LIST]({commit}, dictList) {
            commit(SET_DICT_LIST, dictList);
        },
        [SET_DICT_LEVE_LIST]({commit}, dictLeveList) {
            commit(SET_DICT_LEVE_LIST, dictLeveList);
        },
        [SET_LOOP_CONFIG]({commit}, loopInstanceConfig) {
            commit(SET_LOOP_CONFIG, loopInstanceConfig);
        },
        [SET_ORDER_LOOP]({commit}, orderLoopInstance) {
            commit(SET_ORDER_LOOP, orderLoopInstance);
        },
        [SET_BROWSER_TYPE]({commit}, extBrowserType) {
            commit(SET_BROWSER_TYPE, extBrowserType);
        },
        [SET_WORK_DATA]({commit}, workData) {
            commit(SET_WORK_DATA, workData);
        },
        [SET_HOLIDAY_DATA]({commit}, holidayData) {
            commit(SET_HOLIDAY_DATA, holidayData);
        },
        [SET_ALL_DATA]({commit}, extUser) {
            commit(SET_IS_LOGIN, true);
            commit(SET_TOKEN, extUser.token);
            commit(SET_ORG_CODE, extUser.orgCode);
            commit(SET_USERNAME, extUser.account.name);
            commit(SET_ACCOUNTNAME, extUser.account.username);
            commit(SET_USERID, extUser.account.id);
            commit(SET_OPENID, extUser.account.openid);
            commit(SET_PHONE, extUser.account.phone);
            commit(SET_ACCOUNT, extUser.account);
            commit(SET_PLATFORM, extUser.platform);
            commit(SET_IS_MANAGE, extUser.isManage);
            commit(SET_MANAGEID, extUser.manageUserId);
            commit(SET_MANAGENAME, extUser.manageUserName);
            commit(SET_ROLES, extUser.extServiceRoles);
        }
    },
    modules: {
        tagsView,
    }
};