import request from "@/utils/request";
import qs from "qs";

/**
 * 获取用户首页数据
 * @returns {*}
 */
export function userIndex() {
  return request({
    url: 'user/index.do',
    method: 'post'
  })
}


/**
 * 保存用户满意度
 * @returns {*}
 */
export function saveUserSatisfied(any) {
  return request({
    url: 'satisfied/saveUserSatisfied.do',
    method: 'post',
    data: qs.stringify(any),
  })
}

/**
 * 获取用户信息
 * @returns {*}
 */
export function userInfo() {
  return request({
    url: 'user/info.do',
    method: 'post'
  })
}

/**
 * 验证登录状态
 * @returns {*}
 */
export function checkLogin() {
  return request({
    url: 'user/checkLogin.do',
    method: 'post'
  })
}

/**
 * 地址列表
 * @returns {*}
 */
export function addressList() {
  return request({
    url: 'user/addressList.do',
    method: 'post'
  })
}

/**
 * 获取地址信息
 * @returns {*}
 */
export function getAddress(any) {
  return request({
    url: 'user/getAddress.do',
    method: 'post',
    data: qs.stringify(any),
  })
}


/**
 * 保存地址信息
 * @returns {*}
 */
export function saveAddress(any) {
  return request({
    url: 'user/saveAddress.do',
    method: 'post',
    params: any
  })
}


/**
 * 删除地址信息
 * @returns {*}
 */
export function deletAdress(any) {
  return request({
    url: 'user/deletAdress.do',
    method: 'post',
    data: any
  })
}

/**
 * 获取用户开票信息
 * @param {*} UserId
 */
export function invoiceInfobyUser(userId) {
  return request({
    url: 'invoiceInfo/getInvoicebyUser.do',
    method: 'post',
    data: qs.stringify({
      userId: userId,
    }),
  })
}

/**
 * 获取用户开票记录
 * @param {*} UserId
 */
export function invoiceListbyUser(keyword) {
  return request({
    url: 'invoiceInfo/invoiceListbyUser.do',
    method: 'post',
    data: qs.stringify({
      keyword: keyword,
    }),
  })
}

/**
 * 获取开票信息
 * @param {*} Id
 */
export function invoiceInfobyId(Id) {
  return request({
    url: 'invoiceInfo/getInvoicebyId.do',
    method: 'post',
    data: qs.stringify({
      Id: Id,
    }),
  })
}

/**
 * 获取地址信息
 * @returns {*}
 */
export function saveInvoiceRecord(any) {
  return request({
    url: 'invoiceInfo/saveInvoiceRecord.do',
    method: 'post',
    data: qs.stringify(any),
  })
}

/**
 * 保存地址信息
 * @returns {*}
 */
export function saveInvoice(any) {
  return request({
    url: 'invoiceInfo/saveData.do',
    method: 'post',
    params: any
  })
}

/**
 * 获取开票信息
 * @param {*} Id
 */
export function deleteInvoice(Id) {
  return request({
    url: 'invoiceInfo/deleteInvoice.do',
    method: 'post',
    data: qs.stringify({
      Id: Id,
    }),
  })
}

/**
 * 获取开票记录信息
 * @param {*} Id
 */
export function getInvoiceRecord(Id) {
  return request({
    url: 'invoiceInfo/getInvoiceRecord.do',
    method: 'post',
    data: qs.stringify({
      id: Id,
    }),
  })
}

/**
 * 获取用户列表
 * @param data
 */
export function queryManagePersonnelList(data, loopCode, keyword, checkRole) {
  return request({
    url: 'user/queryManagePersonnelList.do',
    data: qs.stringify({
      data: data,
      keyword: keyword,
      loopCode: loopCode,
      checkRole: checkRole
    }),
    method: 'post'
  })
}

/**
 * 获取某个角色的用户列表
 * @param data
 */
export function queryManagePersonnelRoleList(roleIds, orgCode, excludeUserId) {
  return request({
    url: 'user/queryManagePersonnelRoleList.do',
    data: qs.stringify({
      roleIds: roleIds,
      orgCode: orgCode,
      excludeUserId: excludeUserId
    }),
    method: 'post'
  })
}

/**
 * 获取用户地址
 * @returns {AxiosPromise}
 */
export function userAddList() {
  return request({
    url: 'user/userAddList.do',
    method: 'post'
  })
}


/**
 * 订单详情
 * @param id
 * @returns {AxiosPromise}
 */
export function getOrder(data) {
  return request({
    url: 'user/getOrder.do',
    data: qs.stringify({
      orderId: data
    }),
    method: 'post'
  })
}