<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
      </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive">
    </router-view>
  </div>
</template>
<script>
import {
  SET_BROWSER_TYPE
} from "@/store/modules/app/type";

export default {
  setup() {

  },
  name: 'App',
  data() {
    return {
      data: this.$route.query.data || '',
      check: this.$route.query.check || 0
    }
  },
  created() {
    const that = this
    let extBrowserType = 1
    //获取浏览器类型
    const ua = navigator.userAgent.toLowerCase()
    const isWeixin = ua.indexOf('micromessenger') !== -1;
    if (!isWeixin) {
      const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (isMobile) {
        extBrowserType = 1
      } else {
        extBrowserType = 2
      }
    } else {
      extBrowserType = 0
    }
    that.$store.commit(`app/${SET_BROWSER_TYPE}`, extBrowserType)
  },
  methods: {}
}
</script>

<style>
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
</style>
