import axios from 'axios'
import {getCookieKey, setCookieKey} from './cookie'
import {v4} from 'uuid'
import qs from 'qs'
import store from "@/store/index";
// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_URL, // api 的 VUE_APP_URL
    timeout: 50000 // 请求超时时间(因为需要调试后台,所以设置得比较大)
});

// request拦截器,在请求之前做一些处理
service.interceptors.request.use(
    config => {
        config.headers['Access-Control-Allow-Headers'] = 'x-requested-with,kmwater-extservice-token'
        const data = qs.parse(config.data)
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('postType') && data.postType == 'application/json') {
            config.headers['Content-Type'] = data.postType
        } else {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        }
        let token = getCookieKey(process.env.VUE_APP_TOKEN_KEY)
        if (!token) {
            token = process.env.VUE_APP_COOKIE_EXT_PREFIX + v4()
            setCookieKey(process.env.VUE_APP_TOKEN_KEY,token)
        }
        if (token && token.indexOf(process.env.VUE_APP_COOKIE_EXT_PREFIX)<0){
            token = process.env.VUE_APP_COOKIE_EXT_PREFIX + v4()
            setCookieKey(process.env.VUE_APP_TOKEN_KEY,token)
        }
        config.headers[process.env.VUE_APP_TOKEN_KEY] = token
        const extBrowserType = store.getters.extBrowserType
        //获取自定义参数
        let platform = extBrowserType == 0 ? 1 : extBrowserType == 2 ? 2 : 3
        if (!platform) {
            platform = '3'
            setCookieKey(process.env.VUE_APP_PLATFORM_COOKIE_KEY, platform)
        }
        config.headers['platform'] = platform
        return config;
    },
    error => {
        //console.log(error); // for debug
        Promise.reject(error);
    }
);

// response 拦截器,数据返回后进行一些处理
service.interceptors.response.use(
    response => {
        /**
         * code为非0是抛错 可结合自己业务进行修改
         */
        const res = response.data;
        if (res.code == process.env.VUE_APP_TOKEN_TIME_OUT_CODE) {
            setCookieKey('isLogin', false)
            setCookieKey('name', '')
            setCookieKey('userPhone', '')
            setCookieKey('isManage', '')
            window.location.href = res.data
        } else {
            return res;
        }
    },
    // eslint-disable-next-line no-unused-vars
    error => {
        Promise.reject("网络异常");
    }
);
export default service;
