import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index";
import store from "./store/index";
import vant from 'vant';
import 'vant/lib/index.css';
import "./assets/style/index.css";
const app = createApp(App);
app.use(router);
app.use(store);
app.use(vant);
app.mount('#app')